import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	heroVideo: {
		marginBottom: '5rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '3rem',
		},
	},
	videoCont: {
		[theme.breakpoints.down('md')]: {
			marginTop: '3rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '2rem',
		},
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '14rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0',
		},
	},
}));

export default function ProductFeature({
	data: { productFeature, appLogos },
	location,
}) {
	const classes = useStyles();
	const iosLogo = appLogos.edges[0];
	const androidLogo = appLogos.edges[1];
	const qbLogo = appLogos.edges[2];
	const zapierLogo = appLogos.edges[3];
	const shopifyLogo = appLogos.edges[4];

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	useEffect(() => {
		setSelectedFeature(productFeature.featureSection[0]?._key);
	}, []);

	const {
		_id,
		metaTitle,
		metaDescription,
		marketoId,
		pardotUrl,
		contactForm,
		hero,
		heroImage,
		heroVideo,
		heroAlignCenter,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = productFeature;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			{heroAlignCenter ? (
				heroVideo ? (
					<div className={classes.heroVideo}>
						<Hero hero={hero} feature />
						<Container className={classes.videoCont}>
							<HeroVideo wistiaLink={heroVideo} />
						</Container>
					</div>
				) : (
					<Hero hero={hero} feature />
				)
			) : (
				<HeroImageRight
					_id={_id}
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					feature
				/>
			)}
			<ProductFeatureBody
				productFeature={productFeature}
				iosLogo={iosLogo}
				androidLogo={androidLogo}
				qbLogo={qbLogo}
				zapierLogo={zapierLogo}
				shopifyLogo={shopifyLogo}
			/>
			{/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}

			<div>
				<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				{!!selectedFeature ? (
					<Form
						formId={marketoId}
						pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				) : null}
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		productFeature: sanityProductFeatures(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			slug {
				current
			}
			hero {
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaButtonText
				ctaButtonLink
				internalLink
			}
			heroImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			heroVideo
			heroAlignCenter
			_rawOverviewContent
			overviewImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			featureHeader
			featureSection {
				_key
				title
				cardTitle
				cardFaIcon
				cardIconColor {
					hexValue
				}
				bodySubHeader
				bodyHeader
				image {
					featureImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
				videoVariant
				thumbnailVideoUrl
				androidLink
				iosLink
				qbLink
				# svgVariant
				ctaText
				ctaLink
				internalLink
				_rawBodyCopy
			}
			testimonialTitle
			testimonial {
				title
				header
				testimonialLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				company
				testimonialText
				nameAndTitle
				videoVariant
				image {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 450
							width: 775
							placeholder: BLURRED
						)
					}
				}
			}
			testimonialBackground {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			resourceTitle
			_rawResourceBody
			resources {
				title
				blurb
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaText
				ctaLink
			}
			formBgImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			contactForm {
				header
				privacyPolicy
			}
			marketoId
			pardotUrl
			customerLogosHeader
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			caseStudy {
				ctaBackground {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaHeader
				ctaLink
				ctaSubheader
				ctaText
				internalLink
			}
		}
		appLogos: allSanityAppLogos {
			edges {
				node {
					appLogoTitle
					appLogo {
						asset {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
